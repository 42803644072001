
import Fab from '@mui/material/Fab';
// import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// import { Link as RouterLink } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import Constants from '../../constants/Constants';
import { useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';



import './Register.css';

interface RegisterProps { }

const Register: React.FC<RegisterProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);


    const { registerUser } = useUser();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        registerUser(username, email, password, firstName);
    };

    useEffect(() => {
        if (user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);


    return (
        <Box
            className='register'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: 'auto',
                padding: { xs: 2, sm: 3 },
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Sign up for free
            </Typography>
            <TextField
                fullWidth
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Username"
                variant="outlined"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                helperText={Constants.passwordRequirements}
                margin="normal"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Typography variant="caption" sx={{ textAlign: 'center', mt: 2 }}>
                By clicking on sign up, you agree to the <Link to='/terms'>Terms and Conditions of Use</Link>.
                <br />
                To learn more about how we collect, use, and protect your personal data, please see our <Link to='/privacy'>Privacy Policy</Link>.
            </Typography>

            {user?.isLoading ? (
                <CircularProgress sx={{ mt: 2 }} />
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    Sign Up
                </Button>
            )}

            <Typography variant="body2" sx={{ mt: 2 }}>
                Have an account? <Link to="/login">Log in</Link>
            </Typography>
        </Box>
    );
};

export default Register;