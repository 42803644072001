import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
    const [cartiLoading, setCartiLoading] = useState(false);
    const [apiKeysLoading, setApiKeysLoading] = useState(false);
    const [sessionId, setSessionId] = useState(localStorage.getItem('cartinese_session_id'));
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { getUser, logoutUser } = useUser();


    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification();


    useEffect(() => {
        document.title = 'Profile';
        getUser();
    }, []);

    useEffect(() => {
        if (!user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);

    const handleActivateCartinesePremium = () => {
        setCartiLoading(true);
        if (!sessionId) {
            addNotificationError('Could not activate subscription. Please contact support.');
            return;
        }

        Client_.client_().activateCartiSubscription(sessionId).then(({ err, res }) => {
            setCartiLoading(false);
            if (err) {
                addNotificationError(err);
            } else {
                getUser();
            }
        });
    };

    const handleCreateApiKey = () => {
        setApiKeysLoading(true);
        Client_.client_().createApiKey().then(({ err, res }) => {
            setApiKeysLoading(false);
            if (err) {
                addNotificationError(err);
            } else {
                getUser();
            }
        });
    };

    const handleCopyApiKey = (apiKey: string) => {
        navigator.clipboard.writeText(apiKey);
        addNotificationSuccess('API key copied to clipboard');
    };


    const handleLogout = () => {
        logoutUser();
        addNotificationSuccess('You are now logged out.');
        navigate('/');
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ width: 80, height: 80, mb: 2, bgcolor: 'primary.main' }}>
                    {user.first_name[0]}
                </Avatar>
                <Typography variant="h4" component="h1" gutterBottom>
                    {user.first_name}
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    id='username-field'
                    label='Username'
                    disabled
                    variant='outlined'
                    value={user.username}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    id='email-field'
                    label='Email'
                    disabled
                    variant='outlined'
                    value={user.email}
                />
                <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {user?.carti_premium?.premium_status ? (
                                    <CheckCircleIcon sx={{ color: 'success.main', mr: 1 }} />
                                ) : (
                                    <CancelIcon sx={{ color: 'error.main', mr: 1 }} />
                                )}
                                <Box>
                                    <Typography variant='body1'>Cartinese Premium</Typography>
                                    {user?.carti_premium?.premium_status && user?.carti_premium?.subscription_type && (
                                        <Typography variant='caption' color='text.secondary'>
                                            {user.carti_premium.subscription_type}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {user?.carti_premium?.premium_status ? (
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => navigate('/cartinese')}
                                    startIcon={<LaunchIcon />}
                                >
                                    Access
                                </Button>
                            ) : sessionId && !user?.carti_premium?.premium_status ? (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleActivateCartinesePremium}
                                    disabled={cartiLoading}
                                    startIcon={cartiLoading ? <CircularProgress size={20} /> : <LockOpenIcon />}
                                >
                                    {cartiLoading ? 'Activating...' : 'Activate'}
                                </Button>
                            ) : (
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => navigate('/cartinese/purchase')}
                                    startIcon={<ShoppingCartIcon />}
                                >
                                    Purchase
                                </Button>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom>API Keys</Typography>
                        {user?.api_keys && user?.api_keys?.length > 0 ? (
                            <List>
                                {user.api_keys.map((keyObj: any, index: number) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" component="span">
                                                    {keyObj?.api_key}
                                                </Typography>
                                            }
                                            secondary={
                                                <>
                                                    <Typography variant="caption" color="text.secondary" component="div">
                                                        Created {new Date(keyObj?.created_at).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <IconButton edge="end" aria-label="copy" onClick={() => handleCopyApiKey(keyObj?.api_key)}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">No API keys found</Typography>
                        )}
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={handleCreateApiKey}
                            disabled={apiKeysLoading}
                            startIcon={apiKeysLoading ? <CircularProgress size={20} /> : <VpnKeyIcon />}
                            sx={{ mt: 2 }}
                        >
                            {apiKeysLoading ? 'Creating...' : 'Create New API Key'}
                        </Button>
                    </Paper>
                </Box>

                <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    onClick={() => window.location.href = 'mailto:saarimmm@gmail.com'}
                    sx={{ mb: 2 }}
                    startIcon={<EmailIcon />}
                >
                    Contact Support
                </Button>
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleLogout}
                    startIcon={<ExitToAppIcon />}
                >
                    Logout
                </Button>
            </Paper>
        </Container>
    );
};

export default Profile;