import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

interface FindEventsProps { }

const sortOptions = ['date', 'score', 'venue', 'location', ];

const defaultState = 'california';
const defaultCity = 'san francisco';


const FindEvents: React.FC<FindEventsProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [playlistUrl, setPlaylistUrl] = useState('');
    const [state, setState] = useState(defaultState);
    const [city, setCity] = useState(defaultCity);
    const [eventData, setEventData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [sortBy, setSortBy] = useState('date');

    useEffect(() => {
        document.title = 'Find Events';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const findArtistEvents = (playlistUrl: string, state: string, city: string, sortBy: string) => {
        setIsLoading(true);
        Client_.client_().findArtistEvents(playlistUrl, state, city, sortBy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
            } else {
                setEventData(res?.result?.events);
            }
        });
    }

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleSpotifyUrl);
        setState(defaultState);
        setCity(defaultCity);
        setSortBy('date');
        findArtistEvents(constants.exampleSpotifyUrl, defaultState, defaultCity, 'date');
    }

    const columns: GridColDef[] = [
        {
            field: 'link',
            headerName: 'Event Link',
            width: 150,
            renderCell: (params) => (
                <Link to={params.value} target='_blank' rel='noreferrer'>Event Link</Link>
            )
        },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'venue', headerName: 'Venue', width: 200 },
        { field: 'location', headerName: 'Location', width: 200 },
        {
            field: 'artists',
            headerName: 'Artists',
            width: 300,
            renderCell: (params) => (
                <div>
                    {params.value.map((artist: any) => (
                        <div key={artist.id}>
                            <Link to={artist.link} target='_blank' rel='noreferrer'>{artist.name}</Link>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'score',
            headerName: 'Score',
            width: 100,
            renderHeader: (params) => (
                <Tooltip title='Event score is the total count of songs in your playlist from each artist performing at the event'>
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            )
        },
    ];

    const rows = eventData.map((event: any) => ({
        id: event.id,
        link: event.link,
        name: event.name,
        date: event.date,
        venue: event.venue.name,
        location: event.venue.location,
        artists: event.artistList,
        score: event.score
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <h1>Find Local Concerts and Events</h1>
            {needsSpotifyLogin &&
                <p style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Enter the URL of a Spotify playlist, and see a list of nearby events from the artists in your playlist.</p>
            <p>Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist</p>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '75%' }}>
            <TextField
                sx={{ width: '75%', m: 1 }}
                id='outlined-basic'
                label='Playlist URL'
                variant='outlined'
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />
                <TextField
                    sx={{ width: '75%', m: 1 }}
                    id='outlined-basic'
                    label='State'
                    variant='outlined'
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                />
                <TextField
                    sx={{ width: '75%', m: 1, mb: 2 }}
                    id='outlined-basic'
                    label='City'
                    variant='outlined'
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                />
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant='contained' color='primary' onClick={() => findArtistEvents(playlistUrl, state, city, sortBy)}>Find Events</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>
                }
            </div>
            {eventData.length > 0 &&
                <>
                    <Divider sx={{ width: '75%', mt: 2 }} />
                    <Box sx={{
                        height: { xs: 400, sm: 500 },
                        width: '80%',
                        mt: 2,
                        '& .MuiDataGrid-root': {
                            fontSize: { xs: '0.75rem', sm: '1rem' },
                        },
                    }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20]}
                        disableRowSelectionOnClick
                        autoHeight
                        sx={{
                            '& .MuiDataGrid-cell': {
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                padding: '8px',
                                display: 'flex',
                                alignItems: 'center',
                            },
                            '& .MuiDataGrid-row': {
                                maxHeight: 'none !important',
                            },
                            '& .MuiDataGrid-renderingZone': {
                                maxHeight: 'none !important',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                maxHeight: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        />
                    </Box>
                </>
            }
        </div>
    );
};

export default FindEvents;