import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUser } from '../../stores/actions/StoreActions';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import Link from '../Link';
import './Login.css';

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { loginUser } = useUser();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        loginUser(email, password);
    };

    useEffect(() => {
        if (user?.username) {
            navigate('/profile');
        }
    }, [navigate, user]);


    return (
        <Box
            className='login'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: 'auto',
                padding: { xs: 2, sm: 3 },
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Log in to continue
            </Typography>
            <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                margin="normal"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <Link to='/password-reset' sx={{ alignSelf: 'flex-start', mt: 1, mb: 2 }}>
                Forgot your password?
            </Link>

            {user.isLoading ? (
                <CircularProgress sx={{ mt: 2 }} />
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    Log in
                </Button>
            )}
            <Divider sx={{ width: '100%', my: 3 }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                Don't have an account?
            </Typography>
            <Button
                component={Link}
                to="/register"
                variant="contained"
                color="secondary"
                fullWidth
            >
                Sign Up
            </Button>
        </Box>
    );
};

export default Login;